import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Input, Spin, Typography, message, Drawer, List, Checkbox, Select, Switch } from "antd";
import { useForm, Controller } from "react-hook-form";
import { useEditUserMutation, useGetUsersQuery, useGetGroupsQuery } from "../services/admin/inventoryApi";
import { useNavigate, useParams } from "react-router-dom";
import { User, Group } from "../types.ts";
import "../styles/UpdateUsers.scss";


const { Text } = Typography;
const { Option } = Select;

const EditUser: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { control, handleSubmit, setValue, formState: { errors } } = useForm<User>();
  const { data: userData, error: usersError, isLoading: usersLoading } = useGetUsersQuery({ skip: 0, limit: 1000 });
  const { data: groupsData, isLoading: isLoadingGroups } = useGetGroupsQuery({ skip: 0, limit: 1000 ,
   //@ts-ignore
   is_rep_group:null});
  const [updateUser, { isLoading: updateLoading, isError: updateError, error }] = useEditUserMutation();

  const [selectedGroups, setSelectedGroups] = useState<Group[]>([]);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  useEffect(() => {
    if (userData) {
      const user = userData?.data?.find((item: User) => item.id === parseInt(id || "0"));
      setValue("full_name", user?.full_name || '');
      setValue("email", user?.email || '');
      setValue("blurb", user?.blurb || '');
      setValue("scope", user?.scope || 'student'); // Updated to include scope
      setValue("in_leaderboard", user?.in_leaderboard || false);
      setValue("is_active", user?.is_active || false);
      setSelectedGroups(user?.groups || []);
    }
  }, [userData, setValue]);

  const validatePassword = (password: string) => {
    if (!password) return true; // No password provided, no need to validate
    if (password.length < 8) {
      return "Password must be at least 8 characters long.";
    }
    if (!/[A-Za-z]/.test(password) || !/\d/.test(password)) {
      return "Password must contain both letters and numbers.";
    }
    return true;
  };

  const onSubmit = async (data: User) => {
    const payload: Partial<User> = { 
      ...data,
      id: parseInt(id || "0"),
      //@ts-ignore
      group_ids: selectedGroups.map(group => group.id)
    };

    if (!data.new_password) {
      delete payload.new_password;
    }


    try {
      // Perform the PATCH request
      //@ts-ignore
      await updateUser({ id: parseInt(id || "0"), ...payload }).unwrap();
      message.success('User updated successfully!');
      navigate(-1);
    } catch (err) {
      console.error('Failed to update user:', err);
      message.error('Failed to update user. Please try again.');
    }
  };

  const handleDrawerOpen = () => {
    setIsDrawerVisible(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerVisible(false);
  };

  const handleGroupSelect = (group: Group) => {
    setSelectedGroups(prevGroups => {
      if (prevGroups.some(g => g.id === group.id)) {
        return prevGroups.filter(g => g.id !== group.id);
      }
      return [...prevGroups, group];
    });
  };

  if (usersLoading) return <Spin />;
  if (usersError) return <Text type="danger">Failed to fetch user data.</Text>;

  return (
    <div className="update-user-containers">
      <form onSubmit={handleSubmit(onSubmit)} className="update-user-form">
        <div className="form-item">
          <label>Full Name</label>
          <Controller
            name="full_name"
            control={control}
            rules={{ required: "Full Name is required" }}
            render={({ field }) => (
              <input {...field} placeholder="Full Name" />
            )}
          />
          {errors.full_name && <Text type="danger">{errors.full_name.message}</Text>}
        </div>

        <div className="form-item">
          <label>Email</label>
          <Controller
            name="email"
            control={control}
            rules={{
              required: "Email is required",
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                message: "Invalid email address"
              }
            }}
            render={({ field }) => (
              <input {...field} placeholder="Email" />
            )}
          />
          {errors.email && <Text type="danger">{errors.email.message}</Text>}
        </div>

        <div className="form-item">
          <label>Blurb</label>
          <Controller
            name="blurb"
            control={control}
            render={({ field }) => (
              <textarea {...field} placeholder="Blurb" rows={4} />
            )}
          />
        </div>

        <div className="form-item">
          <label>Scope</label>
          
          <Controller
            name="scope"
            control={control}
            rules={{ required: "Scope is required" }}
            render={({ field }) => (
              <Select {...field} placeholder="Select Scope">
                <Option value="student">Student</Option>
                <Option value="rep">Rep</Option>
              </Select>
            )}
          />
          {errors.scope && <Text type="danger">{errors.scope.message}</Text>}
        </div>

        <div className="form-item">
          <label>New Password</label>
          <Controller
            name="new_password"
            control={control}
            rules={{
              validate: validatePassword
            }}
            render={({ field }) => (
              <Input.Password {...field} placeholder="New Password" />
            )}
          />
          {errors.new_password && <Text type="danger">{errors.new_password.message}</Text>}
        </div>

        <div className="form-item">
  <label>Show Name in Public Leaderboard</label>
  <Controller
    name="in_leaderboard"
    control={control}
    render={({ field }) => (
      <Switch checked={field.value} onChange={field.onChange} />
    )}
  />
</div>

<div className="form-item">
  <label>Is Active</label>
  <Controller
    name="is_active"
    control={control}
    render={({ field }) => (
      <Switch checked={field.value} onChange={field.onChange} />
    )}
  />
</div>

        <div className="form-item">
          <label>Groups</label>
          <Button onClick={handleDrawerOpen}>Select Groups</Button>
          <List
            dataSource={selectedGroups}
            renderItem={group => (
              <List.Item key={group.id}>
                {group.name}
              </List.Item>
            )}
          />
        </div>

        {updateError && (
          <div className="form-item">
            <Text type="danger">{error ? error.toString() : 'Failed to update user. Please try again.'}</Text>
          </div>
        )}

        <div className="form-item">
          <Button type="primary" htmlType="submit" loading={updateLoading}>
            Update User
          </Button>
        </div>
      </form>

      <Drawer
        title="Select Groups"
        width={400}
        onClose={handleDrawerClose}
        visible={isDrawerVisible}
      >
        <List
          dataSource={groupsData ?? []}
          renderItem={(group: Group) => (
            <List.Item key={group.id}>
              <Checkbox
                checked={selectedGroups.some(g => g.id === group.id)}
                onChange={() => handleGroupSelect(group)}
              >
                {group.name}
              </Checkbox>
            </List.Item>
          )}
        />
      </Drawer>
    </div>
  );
};

export default EditUser;
